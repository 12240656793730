










import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SKHero extends Vue {
  get src(): string {
    if (this.$store.getters.darkmode) {
      return 'assets/banner_dark.svg';
    }
    return 'assets/banner_light.svg';
  }
}
