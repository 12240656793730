




















import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class SKHeader extends Vue {
  @Prop() title!: string;
  @Prop() rootRoute!: string;
  @Prop() backTitle!: string;
  @Prop({ default: 150 }) trigger!: number;
  @Prop() dark!: boolean;

  public TRIGGER = this.trigger;
  public enhanced = window.scrollY > this.TRIGGER;

  get isRoot(): boolean {
    return this.$route.name === this.rootRoute;
  }

  mounted() {
    window.addEventListener('scroll', this.scroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll);
  }

  public scroll() {
    this.enhanced = window.scrollY > this.TRIGGER;
  }
}
