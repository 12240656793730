

















import SKHeader from '@/components/SK-Header.vue';
import SKHero from '@/components/SK-Hero.vue';
import SKPosition from '@/components/SK-Position.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKPosition,
    SKHeader,
    SKHero
  }
})
export default class PositionsOverview extends Vue {}
