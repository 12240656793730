




















































import { complete, hasCompleted, hasLiked, like } from '@/utils/functions';
import { IPosition } from '@/utils/interfaces';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SKPosition extends Vue {
  @Prop() position!: IPosition;

  get id(): string {
    if (!this.position) return '1704';
    return this.position._id;
  }

  get liked(): boolean {
    return hasLiked(this.id);
  }

  get completed(): boolean {
    return hasCompleted(this.id);
  }

  public like(): void {
    like(this.id);
  }

  public complete(): void {
    complete(this.id);
  }
}
